import {
  signOutProxy,
  signInProxy,
  signIn as signInRemote,
} from 'src/api/authApi';
import type {
  ProviderSignInOptions,
  SignInDevicePlatformInfo,
  UserCredentials,
  UserType,
} from 'types/objectTypes';
import { Logger } from 'lib/logger';
import { GTM } from 'lib/external/gtm';
import { Exponea } from 'lib/external/exponea';

export const signIn = async (
  { email, password }: UserCredentials,
  { device, path: pageURL }: SignInDevicePlatformInfo,
) => {
  try {
    GTM.sendEvent('OACPSignInTry', { pageURL });
    const data = await signInProxy({ email, password }, device);
    const { customerId, custId } = data;
    GTM.sendEvent('OACPSignInSuccess', { UserID: custId, pageURL });
    Exponea.identify(customerId, {}, true);

    return data;
  } catch (e) {
    GTM.sendEvent('OACPSignInFailed', { pageURL });
    throw e;
  }
};

export const signInWithProvider = async (
  { email, password }: UserCredentials,
  options: ProviderSignInOptions,
) => {
  const { data } = await signInRemote(email, password, options.query);

  return data;
};

export const signOut = async (user: UserType | null) => {
  await signOutProxy();

  if (!user) {
    const warn = 'No authentication data for track while sign out';
    Logger.warn(warn);
  }
};

export const buildPartialAuthObjectFromCustomerDetails = (customerDetails: Record<string, any>) => {
  const partialAuth = {
    country: customerDetails.country,
    city: customerDetails.city,
    isEmployeeAccount: customerDetails.isEmployeeAccount,
    dollarBalance: customerDetails.dollarBalance,
    enableAutoRecharge: customerDetails.enableAutoRecharge,
    availableBalance: customerDetails.availableDollarBalance,
    karmaPoints: customerDetails.karmaPoints,
    userSign: customerDetails.horoSign,
    userEmail: customerDetails.email,
    userDob: customerDetails.birthDate,
    isLeadCustomer: customerDetails.isLeadCustomer,
    isDollarNewCustomer: customerDetails.isDollarNewCustomer,
    isKarmaMember: customerDetails.isKarmaMember,
    isNewCreditCardDisabled: customerDetails.isNewCreditCardDisabled,
    customerPhoneNumber: customerDetails.customerPhoneNumber,
    customerId: customerDetails.customerId,
    isMyAlertEnable: customerDetails.myAlertOptIn,
    nonPrimaryNumber: customerDetails.nonPrimaryNumber,
    userDateOfBirth: customerDetails.userBirthDate,
    isAcceptGDPR: customerDetails.isAcceptGDPR,
    isAppInstalled: customerDetails.isAppInstalled,
    countryCallingCode: customerDetails.countryCallingCode,
    LifecycleStage: customerDetails.lifecycleStage,
    ValueSegmentName: customerDetails.valueSegmentName,
    webLoggingEnabled: customerDetails.webLoggingEnabled,
    phoneCountryCode: customerDetails.phoneCountryCode,
    minutesBalance: customerDetails.minutesBalance,
    krTier: customerDetails.krTier,
    psychicInCircle: customerDetails.psychicsInCircle,
    excludePsychicTier: customerDetails.excludePsychicTier,
  };

  return partialAuth;
};

export const buildObjectForAuthCookie = (
  authData: Record<string, any>,
  customerDetails: Record<string, any>,
) => {
  const authObject: Record<string, any> = {};
  authObject.authToken = `Token ${authData.sessionId}`;
  authObject.bearerToken = authData.bearerToken;
  authObject.refreshToken = authData.refreshToken;
  authObject.chatToken = authData.chatToken;
  authObject.chatTokenExp = authData.chatTokenExp;
  authObject.custId = authData.custId;
  authObject.displayNameHeader = authData.firstName;
  authObject.displayNameMenu = `${authData.firstName} ${authData.lastName}`;
  authObject.firstName = authData.firstName;
  authObject.lastName = authData.lastName;
  authObject.custGroup = authData.custGroup;
  authObject.custSegment = authData.custSegment;
  authObject.isFiveFree = authData.fiveFree;
  authObject.isVIPCustomer = authData.vipCustomer;
  authObject.loggedIn = true;
  const customerDetailsObject = buildPartialAuthObjectFromCustomerDetails(customerDetails);

  return { ...authObject, ...customerDetailsObject };
};
